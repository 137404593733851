function IconSquash({fill}) {
  return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill={fill}
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.05156 12.6148L3.8487 12.403L6.79248 9.32947C8.30771 8.96083 11.5829 8.9818 14.5874 5.84422C15.8034 4.57486 15.3983 2.45897 14.1822 1.1895C12.9666 -0.0798598 10.941 -0.502845 9.72479 0.766368C6.72082 3.9039 6.74025 7.32401 6.38784 8.90635L3.44406 11.9799L3.24174 11.768L0 15.1533L0.810353 16.0001L4.05156 12.6148ZM7.12393 8.01526C7.38696 6.27258 7.78347 3.63998 10.1309 1.18961C11.1321 0.14415 12.8031 0.595393 13.7773 1.61274C14.7515 2.63006 15.1836 4.37577 14.1825 5.42115C11.8358 7.8718 9.31471 8.28667 7.6465 8.56068C7.42248 8.59774 7.21439 8.63189 7.02073 8.66838C7.05611 8.4667 7.08828 8.24883 7.12376 8.01545L7.12393 8.01526Z"
        />
        <path
            d="M14.1542 3.59894C14.0705 3.04424 13.7783 2.4595 13.3719 2.03518C12.8733 1.5145 12.1794 1.19141 11.5604 1.19141C11.1472 1.19141 10.8029 1.33259 10.5353 1.61151C8.41198 3.82809 7.97538 6.23251 7.7146 7.94228C9.30775 7.67832 11.6338 7.23435 13.777 4.99676C14.197 4.55869 14.2142 3.99495 14.1541 3.59883L14.1542 3.59894Z"
        />
      </svg>
  );
}

export default IconSquash;
