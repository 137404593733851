import {applyMiddleware, combineReducers, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import appReducer from './reducers/app-reducer';
import openMatchesReducer from './reducers/open-matches';

const persistConfig = {
  key: 'app',
  storage,
  blackList:['openMatches']
};

// Here we combine different reducers to be used in this app
const rootReducer = combineReducers({
  app: appReducer,
  openMatches:openMatchesReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(...[ thunkMiddleware ]));
const persistor = persistStore(store);

export { store, persistor };
