function IconTennis({fill}) {
  return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M0.31145 13.6895C-0.0149496 13.3631 -0.0149496 12.834 0.31145 12.5076L2.08478 10.7343L3.26665 11.9161L1.49332 13.6895C1.16692 14.0159 0.63785 14.0159 0.31145 13.6895ZM3.66398 11.0911L3.28692 10.714C4.41812 9.58279 5.17278 8.82812 5.17278 8.82812C5.17278 8.82812 7.43678 9.81426 7.31572 10.2575C6.22825 10.2441 4.61758 10.1503 3.66398 11.0911Z"/>
        <path d="M2.90991 10.3356L3.28698 10.7127C4.41818 9.58148 5.17285 8.82681 5.17285 8.82681C5.17285 8.82681 4.18671 6.56281 3.74351 6.68388C3.75685 7.77134 3.85071 9.38201 2.90991 10.3356ZM4.86671 0.599609C4.44237 0.599609 4.0354 0.76818 3.73534 1.06824C3.43528 1.3683 3.26671 1.77526 3.26671 2.19961C3.26671 2.62396 3.43528 3.03092 3.73534 3.33098C4.0354 3.63104 4.44237 3.79961 4.86671 3.79961C5.29106 3.79961 5.69803 3.63104 5.99808 3.33098C6.29814 3.03092 6.46671 2.62396 6.46671 2.19961C6.46671 1.77526 6.29814 1.3683 5.99808 1.06824C5.69803 0.76818 5.29106 0.599609 4.86671 0.599609Z" />
        <path d="M12.3381 1.66235C11.1061 0.42982 9.11574 0.29862 7.3216 1.15729C7.4576 1.47782 7.53334 1.82982 7.53334 2.19995C7.53334 2.21542 7.52907 2.22929 7.52907 2.24475C8.2192 1.86235 8.94827 1.66342 9.62667 1.66342C10.3824 1.66342 11.0763 1.90875 11.584 2.41649C12.8315 3.66395 12.4928 6.03249 10.8293 7.69595C9.16587 9.35942 6.79734 9.69755 5.54987 8.45062C4.68267 7.58289 4.58347 6.17435 5.16107 4.83729C5.06294 4.84795 4.96747 4.86662 4.86667 4.86662C4.5856 4.86662 4.31947 4.81115 4.06507 4.73062C3.47147 6.37222 3.68587 8.09542 4.7952 9.20475C5.50614 9.91569 6.46934 10.2608 7.50187 10.2608C8.8848 10.2608 10.3925 9.64049 11.5829 8.45009C13.6629 6.37062 14.0016 3.32529 12.3381 1.66235Z" />
      </svg>
  );
}

export default IconTennis;
