function IconPadel({fill}) {
  return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill={fill}
           xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.3078 1.64903C12.3078 1.64903 8.77596 -1.7931 5.95147 1.26883C4.33562 2.96737 4.19365 4.23702 4.0983 4.91019C4.00463 5.58174 3.43881 9.46778 3.25145 9.65038C3.1482 9.75019 0.546447 12.1333 0.546447 12.1333C0.546447 12.1333 0.147586 12.2931 0.0418338 12.4433C0.0418338 12.4433 -0.212138 12.4019 0.531458 13.1274C1.27963 13.8566 1.23592 13.6078 1.23592 13.6078C1.38705 13.5015 1.55359 13.1156 1.55359 13.1156C1.55359 13.1156 3.99672 10.5784 4.09955 10.4785C4.28691 10.296 8.27468 9.7437 8.9654 9.65484C9.65612 9.56192 10.9589 9.42396 12.7 7.84714C15.8385 5.08995 12.3078 1.64903 12.3078 1.64903Z"
              />
      </svg>
  );
}

export default IconPadel;
