const initialState={

    singleDetail:{
        loading:false,
        data:null
    },
    loading:false

}


function openMatchesReducer(state=initialState,action){

    switch(action.type){

        case 'OPEN_MATCHES_SET_SINGLE_DETAIL_DATA':
            return{
                ...state,
                singleDetail:{...state.singleDetail,...action.payload}
            }
        case 'OPEN_MATCHES_SET_OPEN_MATCH_DATA_LOADING':
            return{
                ...state,
                loading:action.payload
            }
        case 'OPEN_MATCHES_RESET_STATES':
            return{
                ...initialState
            }
        default:
            return state

    }

}

export default openMatchesReducer;